import { Ref, ref } from 'vue';
import { $api, $loading } from '@/api/index';
import router from '@/router/index';
import { toast } from '@/utils/toast';


export interface SaleInfo {
    assignkey1: string;
    dplaceCode: string;
    dplaceName: string;
    saleCode: string;
    saleName: string;
    saleSano: string;
    saleOwer: number;
    officeCode: string;
    officeName: string;
    deliveryCode: number;
    deliveryName: number;
    orderCode: number;
    orderName: number;
    itnmCode: number;
    itnmName: number;
}

export interface DplaceList{
  dplaceName: string;
  
}

export interface OrderList{
  orderName: string;
  orderCode: string;
}

export interface DeliveryList{
  deleiveryName: string;
  deleiveryCode: string;
}

export interface ItnmList{
  itnmName: string;
  itnmCode: string;
  hogiInfo: string;
}

export interface SaleList{
  saleName: string;
}

export interface HogiList{
  hogiNo: string;
  hogiNam2: string;
}

export interface QuntAble{
  qunt: number;
}

export function saleinfos(){
    const isConnected = ref(true);
    const saleinfolist: Ref<SaleInfo[]> = ref([]);
    const dplacelist: Ref<DplaceList[]> = ref([]);
    const orderlist: Ref<OrderList[]> = ref([]);
    const deliverylist: Ref<DeliveryList[]> = ref([]);
    const itnmlist: Ref<ItnmList[]> = ref([]);
    const salelist: Ref<SaleList[]> = ref([]);
    const hogilist: Ref<HogiList[]> = ref([]);
    const quntable: Ref<QuntAble[]> = ref([]);

    async function fetchDatas(dplaceCode: string) {
     
        await $loading(`/saleinfo?dplaceCode=${dplaceCode}`, 'POST').then(
          (res: any) => {
            if(res.data == ''){
                saleinfolist.value = [];
              toast('데이터가 존재하지 않습니다.'); 
            }else{
              if(res.data[0].errChk == -1){
                toast(res.data[0].txtMsg); 
              }else{
                saleinfolist.value = res.data;
              }
            }
          }
        );
      }

      async function fetchDplaceDatas() {
        await $api(`/dplceinfo`, 'POST').then(
          (res: any) => {
            dplacelist.value = res.data;
          }
        );
      }

      async function fetchOrderDatas() {
        await $api(`/orderinfo`, 'GET').then(
          (res: any) => {
            orderlist.value = res.data;
          }
        );
      }

      async function fetchDeliveryDatas() {
        await $api(`/deliveryinfo`, 'GET').then(
          (res: any) => {
            deliverylist.value = res.data;
          }
        );
      }

      async function fetchItnmDatas() {
        await $api(`/itnminfo`, 'GET').then(
          (res: any) => {
            itnmlist.value = res.data;
            sessionStorage.setItem('itnmlist', JSON.stringify(itnmlist.value))
          }
        );
      }

      async function fetchSaleDatas() {
        await $api(`/saleinfo`, 'GET').then(
          (res: any) => {
            salelist.value = res.data;
          }
        );
      }

      async function fetchHogiDatas() {
        await $api(`/hogiinfo`, 'GET').then(
          (res: any) => {
            hogilist.value = res.data;
          }
        );
      }

      async function fetchQuntData(frDate: string, deliCode: string, dplaceCode: string, itnmCode: string) {
        await $api(`/quntinfo?frDate=${frDate}&deliCode=${deliCode}&dplaceCode=${dplaceCode}&itnmCode=${itnmCode}`, 'POST').then(
          (res: any) => {
            if(res.data == ''){
              
              toast('데이터가 존재하지 않습니다.'); 
            }else{
              quntable.value = res.data;
            }
          }
        );
      }

      async function fetchModQuntData(frDate: string, deliCode: string, dplaceCode: string, itnmCode: string, assignkey1: string) {
        await $api(`/modquntinfo?frDate=${frDate}&deliCode=${deliCode}&dplaceCode=${dplaceCode}&itnmCode=${itnmCode}&assignkey1=${assignkey1}`, 'POST').then(
          (res: any) => {
            if(res.data == ''){
              
              toast('데이터가 존재하지 않습니다.'); 
            }else{
              quntable.value = res.data;
            }
          }
        );
      }

      async function insertAssign(date: string, dplaceCode: string, siCode: string, orderCode: string, itnmCode: string,
                                  deliCode: string, ton: string, transCode: string, remark: string, hogiCode: string) {
        await $loading(`/insertassign?date=${date}&dplaceCode=${dplaceCode}&siCode=${siCode}&orderCode=${orderCode}
                        &itnmCode=${itnmCode}&deliCode=${deliCode}&ton=${ton}&transCode=${transCode}&remark=${remark}&hogiCode=${hogiCode}`
                        , 'POST').then(
          (res: any) => {
            if(res.status == 200){
              toast('등록완료되었습니다.'); 
              router.replace('/home');
            }
          }
        );
      }
    return { 
      isConnected,
      saleinfolist,
      fetchDatas,
      fetchDplaceDatas,
      dplacelist,
      fetchOrderDatas,
      orderlist,
      fetchDeliveryDatas,
      deliverylist,
      fetchItnmDatas,
      itnmlist,
      fetchSaleDatas,
      salelist,
      fetchQuntData,
      quntable,
      insertAssign,
      fetchModQuntData,
      fetchHogiDatas,
      hogilist,
    }
}
