import { Ref, ref } from 'vue';
import { $api } from '@/api/index';
import router from '@/router';
import { toast } from '@/utils/toast';

export type NewCnt = {
  apprCnt: number;
  finalCnt: number;
  credentialsExpired: number;
};
export function useHome() {
  const newCnt: Ref<NewCnt> = ref({
    apprCnt: 0,
    finalCnt: 0,
    credentialsExpired: 0
  });

  async function fetchData() {
    await $api('/news', 'get').then((res: any) => {
      newCnt.value = res.data;
    });
  }
  async function chkAppr(title: string) {
    await $api(`/home/appr/?title=${title}`, 'get').then((res: any) => {
      if(res.data[0].errChk < 0){
        toast(res.data[0].txtMsg)
      }else{
        if(title =='출하신규배정'){
          router.push('/chulhaassignnew')
        }
        else if(title =='출하배정현황')
        {
          router.push('/chulhaassign')
        }
        else if(title =='실시간출하현황')
        {
          router.push('/rtss')
        }
        else if(title =='거래처별출하집계')
        {
          router.push('/accsum')
        }
        else if(title =='출하현황집계')
        {
          router.push('/outtt')
        }
        else if(title =='영업속보')
        {
          router.push('/brnews')
        }
      }
    });
  }
  return {
    newCnt,
    fetchData,
    chkAppr
  };
}
